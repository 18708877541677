import httpRequst from "../utils/request"
// 分类列表
export const acticleClassifyList = function(params) {
    console.log('params', params)
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/acticle/classify`, params)
}
// 文章列表
export const acticleList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/acticle`, params)
}
// 文章详情
export const acticleShow = function(id) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/acticle/${id}`, {})
}
