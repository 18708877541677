<template>
	<div class="pl-store__acticle-wraper">

		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item v-if="info.classify[0]" :to="{ path: `/news/${info.type}` }">
				{{ info.classify[0] && info.classify[0].name }}
			</el-breadcrumb-item>
			<el-breadcrumb-item>正文</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="pl-store__acticle-body">

			<div class="pl-store__acticle-module-title">
				<h2>{{ info.name }}</h2>
				<p>{{ info.updated_at }}</p>
			</div>
			<div class="pl-store__acticle-content-box" v-html="info.content">

			</div>
		</div>
	</div>
</template>
<script>
import {acticleShow} from '@/api/acticle.js';

export default {


	data() {
		return {
			id: this.$route.params.id,
			type: this.$route.params.type,
			info: {}
		}
	},
	created() {
		acticleShow(this.id).then((data) => {
			this.info = data
		})
	}
}
</script>
<style lang="scss" scoped>
.pl-store__acticle-wraper {
	background: #FFFFFF;
	padding: 20px;
}
.pl-store__acticle-module-title {
	color: #1db3c9;
	padding: 0 0 12px 6px;
	border-bottom: 2px solid #e7e7e7;
	// margin: 0 40px;
	font-size: 12px;
	color: #9b9b9b;

	h2 {
		font-size: 18px;
		padding-bottom: 15px;
		color: #000
	}
}

.pl-store__acticle-body {
	padding: 20px 0;
	// background: #fff;
	margin: 40px auto;
}

.pl-store__acticle-content-box {
	padding: 20px 30px;
	// background-color: #fff;

	::v-deep p {
		margin: 15px 0 !important;
		line-height: 1.5;
		text-indent: 2em;
		overflow: hidden;
	}

	::v-deep img {
		display: inline-block;
		margin: auto;
		max-width: 100%;
	}
}
</style>
